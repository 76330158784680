const AUDIT_STATUS_EMPTY = 0;
const AUDIT_STATUS_INPROGRESS = 1;
const AUDIT_STATUS_COLORS = {
  [AUDIT_STATUS_EMPTY]: "grey",
  [AUDIT_STATUS_INPROGRESS]: "orange"
};
const AUDIT_STATUS_TEXTS = {
  [AUDIT_STATUS_EMPTY]: "",
  [AUDIT_STATUS_INPROGRESS]: "In Progress"
};
const AUDIT_CYCLE_STATUS_INPROGRESS = 0;
const AUDIT_CYCLE_STATUS_APPROVED = 1;
const AUDIT_CYCLE_STATUS_COLORS = {
  [AUDIT_CYCLE_STATUS_INPROGRESS]: "orange",
  [AUDIT_CYCLE_STATUS_APPROVED]: "success-6"
};
const AUDIT_CYCLE_STATUS_TEXTS = {
  [AUDIT_CYCLE_STATUS_INPROGRESS]: "In Progress",
  [AUDIT_CYCLE_STATUS_APPROVED]: "Approved"
};
const AUDIT_RECORD_STATUS_INPROGRESS = 0;
const AUDIT_RECORD_STATUS_APPROVED = 1;
const AUDIT_RECORD_STATUS_COMPLETED = 2;
const AUDIT_RECORD_STATUS_COLORS = {
  [AUDIT_RECORD_STATUS_INPROGRESS]: "orange",
  [AUDIT_RECORD_STATUS_APPROVED]: "success-6",
  [AUDIT_RECORD_STATUS_COMPLETED]: "primary-6"
};
const AUDIT_RECORD_STATUS_TEXTS = {
  [AUDIT_RECORD_STATUS_INPROGRESS]: "In Progress",
  [AUDIT_RECORD_STATUS_APPROVED]: "Approved",
  [AUDIT_RECORD_STATUS_COMPLETED]: "Completed"
};
const AUDIT_EVENT_TYPE = {
  NOT_URGENT: 0,
  UPCOMING: 1,
  OVERDUE: 2
};
var AUDIT_CONST = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  AUDIT_STATUS_EMPTY,
  AUDIT_STATUS_INPROGRESS,
  AUDIT_STATUS_COLORS,
  AUDIT_STATUS_TEXTS,
  AUDIT_CYCLE_STATUS_INPROGRESS,
  AUDIT_CYCLE_STATUS_APPROVED,
  AUDIT_CYCLE_STATUS_COLORS,
  AUDIT_CYCLE_STATUS_TEXTS,
  AUDIT_RECORD_STATUS_INPROGRESS,
  AUDIT_RECORD_STATUS_APPROVED,
  AUDIT_RECORD_STATUS_COMPLETED,
  AUDIT_RECORD_STATUS_COLORS,
  AUDIT_RECORD_STATUS_TEXTS,
  AUDIT_EVENT_TYPE
}, Symbol.toStringTag, { value: "Module" }));
export { AUDIT_EVENT_TYPE as A, AUDIT_STATUS_COLORS as a, AUDIT_STATUS_TEXTS as b, AUDIT_RECORD_STATUS_TEXTS as c, AUDIT_RECORD_STATUS_COLORS as d, AUDIT_CYCLE_STATUS_COLORS as e, AUDIT_CYCLE_STATUS_TEXTS as f, AUDIT_CONST as g, AUDIT_RECORD_STATUS_INPROGRESS as h, AUDIT_RECORD_STATUS_COMPLETED as i, AUDIT_RECORD_STATUS_APPROVED as j };
