const DATE_FORMAT = "DD/MM/YYYY";
const DATE_TIME_FORMAT = "DD/MM/YYYY - hh:mm A";
const REPORT_TYPE = "steri-audit";
const REPORT_NAME = {
  DUE: "Due",
  COMPLETED: "Completed",
  RESULT: "Result",
  SCHEDULE: "Schedule",
  NEVER: "NeverAudit"
};
export { DATE_FORMAT as D, REPORT_TYPE as R, DATE_TIME_FORMAT as a, REPORT_NAME as b };
