import "./GroupInfo.vue_vue_type_style_index_0_scoped_true_lang.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import { STERILIZATION_AUDITS_AUDITGROUP_AUDITRECORD } from "@kraftheinz/common";
import { D as DATE_FORMAT, a as DATE_TIME_FORMAT } from "./audit.others.js";
import { i as AUDIT_RECORD_STATUS_COMPLETED } from "./audit.status.js";
import { A as APPROVE_PERMISSION } from "./index.js";
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-card", { scopedSlots: _vm._u([{ key: "title", fn: function() {
    return [_c("h6", { staticClass: "section-title" }, [_vm._v(_vm._s(_vm.groupData.groupName))]), _c("p", { staticClass: "mb-0" }, [_vm._v("Other linked item code (" + _vm._s(_vm.otherItems.length) + "):")])];
  }, proxy: true }]) }, [_c("a-list", { attrs: { "item-layout": "horizontal", "data-source": _vm.otherItems }, scopedSlots: _vm._u([{ key: "renderItem", fn: function(item) {
    return _c("a-list-item", {}, [_c("a-list-item-meta", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_c("span", { staticClass: "text--gray-10" }, [_vm._v(_vm._s(item.itemCode))])];
    }, proxy: true }, { key: "description", fn: function() {
      return [_c("span", { staticClass: "text--gray-7" }, [_vm._v(_vm._s(item.itemDescription))])];
    }, proxy: true }], null, true) })], 1);
  } }]) })], 1);
};
var staticRenderFns$2 = [];
const __vue2_script$2 = {
  name: "GroupInfo",
  props: {
    groupData: {
      type: Object,
      default: () => {
      }
    },
    itemId: {
      type: [String, Number],
      default: void 0
    }
  },
  data() {
    return {
      otherItems: []
    };
  },
  watch: {
    groupData(newVal) {
      this.otherItems = this.getListOtherItems(newVal);
    }
  },
  methods: {
    getListOtherItems(groupData) {
      const { auditItems } = groupData;
      if (auditItems && typeof this.itemId !== "undefined") {
        return auditItems.filter((item) => item.id != this.itemId);
      }
      return [];
    }
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, "519fc62e", null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var GroupInfo = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "record-form" }, [_c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-card", { scopedSlots: _vm._u([{ key: "actions", fn: function() {
      return [_c("div", { staticClass: "px-4" }, [_c("a-row", { attrs: { "type": "flex", "justify": "end", "gutter": 8 } }, [_c("a-col", [_c("a-button", { on: { "click": _vm.onCancel } }, [_vm._v("Cancel")])], 1), _c("a-col", [!_vm.isEdit ? _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.addNew);
      } } }, [_vm._v("Create")]) : _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary", "disabled": _vm.isDisabled }, on: { "click": function($event) {
        return handleSubmit(_vm.saveChanges);
      } } }, [_vm._v("Save")])], 1)], 1)], 1)];
    }, proxy: true }], null, true) }, [_c("a-row", { attrs: { "type": "flex", "justify": "space-between" } }, [_vm.dataItem ? _c("a-col", [_c("h4", { staticClass: "component-title" }, [_vm._v(_vm._s(_vm.dataItem.itemCode))]), _c("p", [_vm._v(_vm._s(_vm.dataItem.itemDescription))])]) : _vm._e(), _vm.isSwitchApproved && _vm.isEdit ? _c("a-col", [_vm.can(_vm.permissions.approve) ? _c("switch-input", { key: "isApprove", attrs: { "value": _vm.isApproved, "label": "Approve", "label-horizontal": "left" }, on: { "change": _vm.changeStatus } }) : _vm._e()], 1) : _vm._e()], 1), _c("a-row", { attrs: { "gutter": [12, 8] } }, [_c("a-col", { attrs: { "xs": 12, "lg": 8 } }, [_c("date-picker", { staticClass: "w-100", attrs: { "format-date": _vm.DATE_FORMAT, "value": _vm.isEdit ? _vm.entity.auditDate : null, "label": "Audit Date", "form-item": "", "rules": "required", "disabled": _vm.isDisabled }, on: { "change": function($event) {
      return _vm.storeValue("auditDate", $event);
    } } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 8 } }, [_c("text-input", { attrs: { "label": "Audit Cycle", "value": _vm.isEdit ? _vm.entity.auditCycleNo : _vm.groupEntity.auditCycleNo, "disabled": "", "form-item": "" } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 8 } }, [_c("date-picker", { attrs: { "label": "Audit Due Date", "value": _vm.groupEntity.nextAuditDate, "format-date": _vm.DATE_FORMAT, "disabled": "", "form-item": "" } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 8 } }, [_c("select-filter", { key: "ProcessHistoryId", attrs: { "value": _vm.isEdit ? { value: _vm.entity.processHistoryId } : null, "label": "Process History", "lazy": "", "placeholder": "Select a process history", "reference": "sterilization.common.process-histories", "source": "id", "source-label": "description", "search-by": "Description", "form-item": "", "rules": "required", "disabled": _vm.isDisabled, "delete-filter": "IsInactive" }, on: { "change": function($event) {
      return _vm.storeValue("processHistoryId", $event.value);
    } } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 8 } }, [_c("select-filter", { key: "TpContactId", attrs: { "value": _vm.isEdit ? { value: _vm.entity.tpContactId } : null, "label": "TP Contact Name", "lazy": "", "placeholder": "Select a TP contact", "reference": "sterilization.common.tp-contacts", "source": "id", "source-label": "name", "search-by": "Name", "form-item": "", "rules": "required", "disabled": _vm.isDisabled, "delete-filter": "IsInactive" }, on: { "change": function($event) {
      return _vm.storeValue("tpContactId", $event.value);
    } } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 8 } }, [_c("select-filter", { key: "PdContactIdId", attrs: { "value": _vm.isEdit ? { value: _vm.entity.pdContactId } : null, "label": "PD Contact Name", "lazy": "", "placeholder": "Select a PD contact", "reference": "sterilization.common.pd-contacts", "source": "id", "source-label": "name", "search-by": "Name", "form-item": "", "disabled": _vm.isDisabled, "delete-filter": "IsInactive" }, on: { "change": function($event) {
      return _vm.storeValue("pdContactId", $event.value);
    } } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 8 } }, [_c("select-filter", { key: "FillingLineId", attrs: { "value": _vm.isEdit ? { value: _vm.entity.fillingLineId } : null, "label": "Filling Line", "lazy": "", "placeholder": "Select a filling line", "reference": "sterilization.common.filling-lines", "source": "id", "source-label": "description", "search-by": "Description", "form-item": "", "rules": "required", "disabled": _vm.isDisabled, "delete-filter": "IsInactive" }, on: { "change": function($event) {
      return _vm.storeValue("fillingLineId", $event.value);
    } } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 8 } }, [_c("select-filter", { key: "SteriliserId", attrs: { "value": _vm.isEdit ? { value: _vm.entity.steriliserId } : null, "label": "Steriliser", "lazy": "", "placeholder": "Select a steriliser", "reference": "sterilization.common.sterilisers", "source": "id", "source-label": "description", "search-by": "Description", "form-item": "", "rules": "required", "disabled": _vm.isDisabled, "delete-filter": "IsInactive" }, on: { "change": function($event) {
      return _vm.storeValue("steriliserId", $event.value);
    } } })], 1)], 1), _c("a-row", { attrs: { "gutter": [12, 8] } }, [_c("a-col", { attrs: { "xs": 12, "lg": 8 } }, [_c("text-input", { attrs: { "value": _vm.isEdit ? _vm.entity.processCycleNo : null, "label": "Process Cycle / No", "type": "number", "placeholder": "0", "form-item": "", "rules": "required|integer", "disabled": _vm.isDisabled }, on: { "change": function($event) {
      return _vm.storeValue("processCycleNo", $event);
    } } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 8 } }, [_c("text-input", { attrs: { "value": _vm.isEdit ? _vm.entity.cpm : null, "label": "CPM", "type": "number", "placeholder": "integer", "form-item": "", "rules": "integer", "disabled": _vm.isDisabled }, on: { "change": function($event) {
      return _vm.storeValue("cpm", $event);
    } } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 8 } }, [_c("text-input", { attrs: { "value": _vm.isEdit ? _vm.entity.processTemp : null, "label": "Process Temp", "type": "text", "suffix": "\u02DAC", "form-item": "", "rules": { required: true, regex: _vm.decimalRegex }, "disabled": _vm.isDisabled }, on: { "change": function($event) {
      return _vm.storeValue("processTemp", $event);
    } } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 8 } }, [_c("text-input", { attrs: { "value": _vm.isEdit ? _vm.entity.processTime : null, "label": "Process Time", "type": "number", "suffix": "mins", "form-item": "", "rules": "required|number", "disabled": _vm.isDisabled }, on: { "change": function($event) {
      return _vm.storeValue("processTime", $event);
    } } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 8 } }, [_c("text-input", { attrs: { "value": _vm.isEdit ? _vm.entity.rpm : null, "label": "RPM", "type": "number", "placeholder": "integer", "form-item": "", "rules": "integer", "disabled": _vm.isDisabled }, on: { "change": function($event) {
      return _vm.storeValue("rpm", $event);
    } } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 8 } }, [_c("text-input", { attrs: { "value": _vm.isEdit ? _vm.entity.refTemp : null, "label": "Ref Temp", "type": "number", "suffix": "\u02DAC", "form-item": "", "rules": { required: true, regex: _vm.decimalRegex }, "disabled": _vm.isDisabled }, on: { "change": function($event) {
      return _vm.storeValue("refTemp", $event);
    } } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 8 } }, [_c("text-input", { attrs: { "value": _vm.isEdit ? _vm.entity.zValue : null, "label": "Z Value", "type": "number", "placeholder": "decimal", "form-item": "", "rules": { required: true, regex: _vm.decimalRegex }, "disabled": _vm.isDisabled }, on: { "change": function($event) {
      return _vm.storeValue("zValue", $event);
    } } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 8 } }, [_c("text-input", { attrs: { "value": _vm.isEdit ? _vm.entity.viscosity : null, "label": "Viscosity", "type": "number", "placeholder": "decimal", "form-item": "", "rules": { required: true, regex: _vm.decimalRegex }, "disabled": _vm.isDisabled }, on: { "change": function($event) {
      return _vm.storeValue("viscosity", $event);
    } } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 8 } }, [_c("range-number-input", { attrs: { "value": _vm.isEdit ? _vm.entity.viscositySpecs : null, "label": "Viscosity Specs", "values": [0, 0], "form-item": "", "rules": { required: true, regex: _vm.decimalRegex }, "disabled": _vm.isDisabled }, on: { "change": function($event) {
      return _vm.storeValue("viscositySpecs", $event);
    } } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 8 } }, [_c("text-input", { attrs: { "value": _vm.isEdit ? _vm.entity.probeSpacer : null, "label": "Probe Spacer", "type": "number", "suffix": "mm", "form-item": "", "rules": "required|integer", "disabled": _vm.isDisabled }, on: { "change": function($event) {
      return _vm.storeValue("probeSpacer", $event);
    } } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 8 } }, [_c("text-input", { attrs: { "value": _vm.isEdit ? _vm.entity.pouchSpacer : null, "label": "Pouch Spacer", "type": "number", "suffix": "mm", "form-item": "", "rules": "required|integer", "disabled": _vm.isDisabled }, on: { "change": function($event) {
      return _vm.storeValue("pouchSpacer", $event);
    } } })], 1), _c("a-col", { attrs: { "xs": 12, "lg": 8 } }, [_c("text-input", { attrs: { "value": _vm.isEdit ? _vm.entity.pouchCanSetUp : null, "label": "Pouch Can Setup", "type": "text", "form-item": "", "max-length": 50, "rules": "required", "disabled": _vm.isDisabled }, on: { "change": function($event) {
      return _vm.storeValue("pouchCanSetUp", $event);
    } } })], 1)], 1), _c("a-row", [_c("a-col", { attrs: { "xs": 24 } }, [_vm.isEdit ? _c("p", [_vm._v(" Last modified by " + _vm._s(_vm.entity.updatedBy) + " on " + _vm._s(_vm._f("moment")(_vm.entity.updatedAt, _vm.DATE_TIME_FORMAT)) + " ")]) : _vm._e()])], 1)], 1)];
  } }]) })], 1);
};
var staticRenderFns$1 = [];
var RecordForm_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".component-title[data-v-b0942518]{font-weight:500;font-size:24px;line-height:1.4;margin-bottom:.2em}[data-v-b0942518] .ant-card-actions{background-color:#fff}\n")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "RecordForm",
  inject: ["can", "resourceProps"],
  data() {
    const [groupProps, itemProps, historyProps] = this.resourceProps;
    const { groupId, itemId } = this.$route.query;
    const historyApiUrl = `${apiUrl$1}/sterilization/audit-groups/${groupId}/items/${itemId}/histories`;
    const isEdit = this.$route.name === "EditAuditRecord";
    return {
      DATE_FORMAT,
      DATE_TIME_FORMAT,
      AUDIT_RECORD_STATUS_COMPLETED,
      apiUrl: apiUrl$1,
      historyApiUrl,
      groupProps,
      itemProps,
      historyProps,
      groupId,
      itemId,
      isLoading: false,
      isEdit,
      isSwitchApproved: false,
      decimalRegex: /^[0-9]\d*(\.\d+)?$/,
      entity: {},
      isApproved: false,
      permissions: {
        approve: APPROVE_PERMISSION
      }
    };
  },
  computed: {
    groupEntity() {
      return this.groupProps.crud.getEntity(this.groupId) || {};
    },
    isDisabled() {
      return this.isEdit && (this.entity.isApprove || !this.isSwitchApproved);
    },
    recordEntity() {
      if (this.isEdit) {
        const { recordId } = this.$route.params;
        return this.historyProps.crud.getEntity(recordId);
      }
      return {};
    },
    dataItem() {
      if (this.groupEntity && this.groupEntity.auditItems && this.itemId) {
        const found = this.groupEntity.auditItems.find((item) => item.id == this.itemId);
        return found;
      }
      return {};
    }
  },
  watch: {
    async recordEntity(newVal) {
      if (newVal != void 0) {
        this.entity = newVal;
        this.isApproved = this.entity.isApprove;
        this.isSwitchApproved = await this.isEditApproved();
        this.storeValues();
        if (this.isApproved) {
          this.$notification["warning"]({
            message: "You can not edit an approved audit."
          });
        }
      }
    }
  },
  destroyed() {
    if (this.isEdit) {
      this.historyProps.crud.clearEntity("editForm");
    } else {
      this.historyProps.crud.clearEntity("createForm");
    }
  },
  methods: {
    async isEditApproved() {
      if (this.recordEntity.status === AUDIT_RECORD_STATUS_COMPLETED)
        return false;
      const { data } = await this.axios.get(`${this.apiUrl}/sterilization/audit-groups/${this.groupId}/cycles`);
      if (!data || !data.results || data.results.length <= 0)
        return true;
      let nextItem = data.results.find((e) => e.auditCycleNo - 1 === this.recordEntity.auditCycleNo);
      if (!nextItem)
        return true;
      if (!nextItem.status && nextItem.status !== 0)
        return true;
      return false;
    },
    storeValues() {
      const entries = Object.entries(this.entity);
      if (entries.length > 0) {
        entries.forEach(([key, value]) => {
          if (key !== "auditItems") {
            this.storeValue(key, value);
          }
        });
      }
    },
    storeValue(resourceKey, value) {
      const formType = this.isEdit ? "editForm" : "createForm";
      this.historyProps.crud.updateEntity(formType, {
        resourceKey,
        value
      });
    },
    async addNew() {
      this.isLoading = true;
      try {
        const response = await this.historyProps.crud.submitEntity("create", `${this.historyApiUrl}?cycleId=${this.groupEntity.auditCycleId}`);
        if (response && response.data && response.data.id) {
          const { id } = response.data;
          this.goToEdit(id);
        }
      } finally {
        this.isLoading = false;
      }
    },
    async saveChanges() {
      this.isLoading = true;
      const { recordId } = this.$route.params;
      try {
        const response = await this.historyProps.crud.submitEntity("replace", `${this.historyApiUrl}/${recordId}?cycleId=${this.groupEntity.auditCycleId}`);
        if (response && response.data && response.data.id) {
          this.$emit("updated");
        }
      } finally {
        this.isLoading = false;
      }
    },
    goToEdit(recordId) {
      this.$router.push({
        name: "EditAuditRecord",
        params: {
          recordId
        },
        query: {
          groupId: this.groupId,
          itemId: this.itemId
        }
      });
    },
    onCancel() {
      this.$router.push(`/sterilization-audits/audit-groups/${this.groupId}/item/${this.itemId}`);
    },
    changeStatus(value) {
      this.isApproved = value;
      if (value) {
        this.openApproveModal();
      } else {
        this.openRevertApprovalModal();
      }
    },
    openApproveModal() {
      const config = {
        class: "record__modal-approve",
        icon: "check-circle",
        title: "Do you want to approve this Audit?",
        content: "You will not be able to make changes after approving this Audit.",
        cancelText: "Cancel",
        okText: "Approve",
        okType: "primary"
      };
      this.$confirm({
        ...config,
        onOk: this.changeApproveStatus,
        onCancel: this.revertStatus
      });
    },
    openRevertApprovalModal() {
      const config = {
        class: "record__modal-revert-approval",
        icon: "exclamation-circle",
        title: "Are you sure you want to set this Audit back to In Progress?",
        cancelText: "Cancel",
        okText: "Yes",
        okType: "primary"
      };
      this.$confirm({
        ...config,
        onOk: this.changeUnapproveStatus,
        onCancel: this.revertStatus
      });
    },
    async changeUnapproveStatus() {
      this.isLoading = true;
      const response = await this.axios.post(`${this.apiUrl}/sterilization/audit-groups/${this.groupId}/cycles/${this.recordEntity.auditCycleId}/unapprove`, {
        auditGroupId: this.groupId,
        auditCycleId: this.recordEntity.auditCycleId
      });
      if (response && (response.status === 204 || response.status === 200)) {
        this.isLoading = false;
        this.$emit("updated");
      } else {
        this.isApproved = this.entity.isApprove;
      }
    },
    async changeApproveStatus() {
      const { recordId } = this.$route.params;
      const res = await this.historyProps.crud.submitPartialEntity({
        resourceName: this.historyProps.resourceName,
        data: [
          {
            op: "replace",
            path: "/IsApprove",
            value: this.isApproved
          }
        ],
        customUrl: `${this.historyApiUrl}/${recordId}/status`
      }).catch(() => {
        this.isApproved = this.entity.isApprove;
      });
      if (res && res.status === 200) {
        this.$emit("updated");
      }
    },
    revertStatus() {
      this.isApproved = this.entity.isApprove;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "b0942518", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var RecordForm$1 = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "record-form-container" }, [_c("resource", { attrs: { "name": "sterilization.common.process-histories", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.common.tp-contacts", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.common.pd-contacts", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.common.filling-lines", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.common.sterilisers", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "page": _vm.page, "name": ["sterilization.audit-groups", "sterilization.audit-groups.items", "sterilization.audit-groups.items.histories"], "api-url": _vm.apiUrl } }, [_c("record-form", { on: { "updated": function($event) {
    return _vm.$emit("updated");
  } } })], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "RecordFormContainer",
  components: {
    RecordForm: RecordForm$1
  },
  data() {
    return {
      apiUrl,
      page: STERILIZATION_AUDITS_AUDITGROUP_AUDITRECORD
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var RecordForm = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { GroupInfo as G, RecordForm as R };
